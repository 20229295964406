<style lang="less">
	.tScienceExplain{
		.ivu-modal {
			width: 660px !important;
		}
	}
</style>
<template>
	<Modal v-model="show" :title="editTitle" class="tScienceExplain"  :mask-closable="false">
		<Form ref="tScienceExplainForm" :model="tScienceExplainForm" :rules="tScienceExplainFormRule" :label-width="100">
					<FormItem label="" prop="id">
						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tScienceExplainForm.id" placeholder="请输入"/>
					</FormItem>
					<FormItem label="" prop="diagnose">
						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tScienceExplainForm.diagnose" placeholder="请输入"/>
					</FormItem>
					<FormItem label="" prop="suggestion">
						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tScienceExplainForm.suggestion" placeholder="请输入"/>
					</FormItem>
					<FormItem label="" prop="science_explain">
						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tScienceExplainForm.scienceExplain" placeholder="请输入"/>
					</FormItem>
		</Form>
		<div slot="footer">
			<Button type="text" @click="show=false">取消</Button>
			<Button type="primary" :loading="loading" @click="handSubmit" v-if="!disabled">提交</Button>
		</div>
	</Modal>
</template>
<script>
	import {addTScienceExplain,updateTScienceExplain,getTScienceExplain} from '@/api/healthy/tScienceExplain'
	import { getDictDataByType } from '@/api/index';
	export default {
		name: "updateTScienceExplain",
		props: {
			value: {
				type: Boolean,
				default: false
			},
			TScienceExplainId: {
				type: String
			},
			modalTitle: {
				type: String
			}
		},
		data() {
			return {
				show: this.value,
				editTitle: this.modalTitle,
				loading: true,
				disabled:false,
				tScienceExplainForm: {
					id:'',
					diagnose:'',
					suggestion:'',
					scienceExplain:'',
				},
				tScienceExplainFormRule: this.getTScienceExplainFormRule()
			}
		},
		methods: {
			handSubmit() {
				this.$refs['tScienceExplainForm'].validate((valid) => {
					this.loading = true;
					if (valid) {
						if(this.TScienceExplainId!=null&&this.TScienceExplainId.trim().length>0){
							this.tScienceExplainForm.id=this.TScienceExplainId;
							updateTScienceExplain(this.tScienceExplainForm).then(res => {
								if (res.success) {
									this.closeModal(false);
									this.$emit('handSearch');
									this.$Message.success('保存成功');
								}else{
									this.$Message.error(res.msg);
								}
							}).finally(() => {
								this.loading = false;
							});
						}else{
							addTScienceExplain(this.tScienceExplainForm).then(res => {
								if (res.success) {
									this.closeModal(false);
									this.$emit('handSearch');
									this.$Message.success('保存成功');
								}else{
									this.$Message.error(res.msg);
								}
							}).finally(() => {
								this.loading = false;
							});
						}
					} else {
							this.loading = false;
						this.$Message.error('表单验证不通过！');
					}
				});
			},
			closeModal(val) {
				this.$emit('input', val);
			},
			initForm(){
				this.tScienceExplainForm= {
					id:'',
					diagnose:'',
					suggestion:'',
					scienceExplain:'',
				};
			},
			getTScienceExplainFormRule() {
				return {
					id: [
						{required: true, message: '不能为空！', trigger: 'blur',pattern: /.+/ },
					],
					diagnose: [
						{required: true, message: '不能为空！', trigger: 'blur',pattern: /.+/ },
					],
					suggestion: [
						{required: true, message: '不能为空！', trigger: 'blur',pattern: /.+/ },
					],
					scienceExplain: [
						{required: true, message: '不能为空！', trigger: 'blur',pattern: /.+/ },
					],
				}
			}
		},
		watch: {
			value(val) {
				this.show = val;
			},
			show(val) {
				this.initForm();
				this.loading=false;
				this.editTitle = this.modalTitle;
				if(this.editTitle == "查看"){
					this.disabled = true;
				}
				else{
					this.disabled = false;
				}
				if(val) {
					this.$refs['tScienceExplainForm'].resetFields();
					this.$refs['tScienceExplainForm'].id = null;
					if(this.TScienceExplainId!=null&&this.TScienceExplainId.trim().length>0){
						getTScienceExplain({id: this.TScienceExplainId}).then(res => {
								if (res.success) {
									 this.tScienceExplainForm = res.data;
							} else {
								this.$Message.error(res.msg);
							}
						});
					}
				} else {
					this.closeModal(val);
				}
			}
		}
	}
</script>
